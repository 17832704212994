import { render, staticRenderFns } from "./helpAssignInfo.vue?vue&type=template&id=25fc008a&scoped=true"
import script from "./helpAssignInfo.vue?vue&type=script&lang=js"
export * from "./helpAssignInfo.vue?vue&type=script&lang=js"
import style0 from "./helpAssignInfo.vue?vue&type=style&index=0&id=25fc008a&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fc008a",
  null
  
)

export default component.exports