var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drag__container asg-table-main"},[_c('div',{staticClass:"toAssigned--left"},[_c('div',{staticClass:"operation__content"},[_c('p',[_vm._v("待分配人员")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleOpen('add', {}, -1, $event)}}},[_vm._v("新增成员")])],1),_c('draggable',{staticClass:"content",attrs:{"options":{
        group: { name: 'orgPerson', put: true, pull: true },
        sort: false,
      }},model:{value:(_vm.waitAssign),callback:function ($$v) {_vm.waitAssign=$$v},expression:"waitAssign"}},_vm._l((_vm.waitAssign),function(item,index){return _c('li',{key:item.name,staticClass:"drag__item",on:{"click":function($event){return _vm.handleOpen('edit', item, index, $event)}}},[_c('header',[_c('p',[_vm._v(_vm._s(item.name))])]),_c('main',[_c('div',{staticClass:"body"},[_c('p',{staticClass:"sex"},[_vm._v(_vm._s(item.sex === "1" ? "男" : "女"))]),_c('div',{staticClass:"role"},[_c('p',{staticClass:"role_name"},[_vm._v(_vm._s(item.role))])])])])])}),0)],1),_c('div',{staticClass:"some_org"},[_c('div',{staticClass:"header_operation"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v("新增区域")]),_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":_vm.handleSave}},[_vm._v("保存")])],1),_c('div',{staticClass:"main_grid"},_vm._l((_vm.layoutConfig),function(item){return _c('div',{key:item.code,staticClass:"some__item--grid"},[_c('div',{staticClass:"label"},[_c('p',[_vm._v(_vm._s(item.label))])]),_c('draggable',{staticClass:"content",attrs:{"options":{
            group: { name: 'orgPerson', put: true, pull: true },
            sort: false,
          }},model:{value:(item.data),callback:function ($$v) {_vm.$set(item, "data", $$v)},expression:"item.data"}},_vm._l((item.data),function(item){return _c('li',{key:item.name,staticClass:"drag__item"},[_c('header',[_c('p',[_vm._v(_vm._s(item.name))])]),_c('main',[_c('div',{staticClass:"body"},[_c('p',{staticClass:"sex"},[_vm._v(_vm._s(item.sex === "1" ? "男" : "女"))]),_c('div',{staticClass:"role"},[_c('p',{staticClass:"role_name"},[_vm._v(_vm._s(item.role))])])])])])}),0)],1)}),0)]),_c('personInfoDialog',{ref:"personInfoDialog",on:{"finish":_vm.handleFinish}}),_c('el-dialog',{attrs:{"title":"区域新增","width":"30%","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"blockForm",attrs:{"model":_vm.form,"label-position":"right","label-width":"120px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"区域编码","prop":"code"}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('el-form-item',{attrs:{"label":"区域名称","prop":"label"}},[_c('el-input',{attrs:{"size":"small"},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})],1)],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.addBlock}},[_vm._v("保存")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }