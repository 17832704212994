<template>
    <div>2222</div>
</template>

<script>
export default {
  name:'test-1',
    data() {
      return {

      };
    },
}
</script>
<style lang='less' scoped>
</style>