<template>
    <userInfoPc></userInfoPc>
</template>

<script>
import userInfoPc from '@/view/userInfo/index.vue';
export default {
    name: 'userInfo',
    components:{
        userInfoPc
    },
    data() {
        return {

        };
    },
    methods: {

    },
    created() {

    },
}
</script>
<style lang='less' scoped></style>