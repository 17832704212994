import { render, staticRenderFns } from "./AsgCheckBoxDoc.vue?vue&type=template&id=c164266e&scoped=true"
import script from "./AsgCheckBoxDoc.vue?vue&type=script&lang=js"
export * from "./AsgCheckBoxDoc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c164266e",
  null
  
)

export default component.exports