// 一览表常数
export const allFlowInfo = [
    {
        parentTitle:'众投',
        parentInfo:'众投赛事主策划',
        tag:'起源',
        children:[
            {
                tag:'发起',
                childInfo:'赛事组发起'
            },
            {
                tag:'投举',
                childInfo:'赛事组投举主策'
            }
        ]
    },
    {
        parentTitle:'准备',
        parentInfo:'赛事主策划筹备赛事流程',
        tag:'筹备',
        children:[
            {
                tag:'规则',
                childInfo:'规划赛事规则'
            },
            {
                tag:'赛季',
                childInfo:'主策划发布新的赛季'
            },
            {
                tag:'报名',
                childInfo:'等待选手官网报名、审核报名队伍'
            },
            {
                tag:'抽签',
                childInfo:'完成赛程抽签直播'
            },
            {
                tag:'赛程排班',
                childInfo:'后台发布赛程、进行排班'
            },
            {
                tag:'审批',
                childInfo:'业务审批新入职裁判导播解说'
            },
            {
                tag:'督促',
                childInfo:'督促解说、导播、裁判进行选班'
            }
        ]
    },
    {
        parentTitle:'宣发',
        parentInfo:'进行赛前宣发',
        tag:'积累',
        children:[
            {
                tag:'制作',
                childInfo:'制作宣发视频、海报进行宣发'
            },
            {
                tag:'直播',
                childInfo:'导播直播赛事、负责人上传回放视频'
            }
        ]
    },
    {
        parentTitle:'公示',
        parentInfo:'赛程结束后的处理',
        tag:'发布',
        children:[
            {
                tag:'冠军',
                childInfo:'登记冠军成员，发放奖金'
            },
            {
                tag:'功勋',
                childInfo:'登记办赛者功勋，给予激励'
            },
            {
                tag:'关闭赛季',
                childInfo:'赛季登记那边关闭赛季'
            },
            {
                tag:'报表',
                childInfo:'登记、填报网易众创激励反馈表'
            },
            {
                tag:'预算',
                childInfo:'预算录入，发放奖金、工资。'
            }
        ]
    },
    {
        parentTitle:'归档',
        parentInfo:'完成赛季结束后勤处理',
        tag:'归档',
        children:[
            {
                tag:'维护',
                childInfo:'维护团队成员'
            },
            {
                tag:'反思',
                childInfo:'反思办赛中的不足，并进行改正'
            },
            {
                tag:'回顾',
                childInfo:'回顾全程进行宣发操作'
            }
        ]
    },
]