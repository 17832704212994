import { render, staticRenderFns } from "./detailSchedule.vue?vue&type=template&id=d84b4dc6&scoped=true"
import script from "./detailSchedule.vue?vue&type=script&lang=js"
export * from "./detailSchedule.vue?vue&type=script&lang=js"
import style0 from "./detailSchedule.vue?vue&type=style&index=0&id=d84b4dc6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d84b4dc6",
  null
  
)

export default component.exports